
export default {
  name: "IndexPage",
  layout: "default",
  
  async asyncData({ $axios,req }) {
    // type:1、首页顶部banner，2、首页小图推荐，3、中部banner，4、，5、推荐文章栏目，6、底部banner，7、社交链接
    const indexBanner = await $axios.post("home/indexBanner", {
      site: 1,
      type: 1,
    });
    const secondaryBanner = await $axios.post("home/indexBanner", {
      site: 1,
      type: 2,
    });
    const indexGoodsRankLists = await $axios.post("home/indexGoodsRankLists", {
      site: 1,
    });
    const midBanner = await $axios.post("home/indexBanner", {
      site: 1,
      type: 3,
    });
    const articleList = await $axios.post("home/indexBanner", {
      site: 1,
      type: 5,
    });

    return { 
      indexBanner: indexBanner.result.data,
      secondaryBanner: secondaryBanner.result.data,
      indexGoodsRankLists: indexGoodsRankLists.result,
      midBanner: midBanner.result.data,
      articleList: articleList.result.data };
  },
  data() {
    return {
      indexBanner: [],
      secondaryBanner: [],
      indexGoodsRankLists: [],
      midBanner: [],
      articleList: [],
      shopCarBall: false,
      shopCarBallEl: null,
      ss: null,
    };
  },
  created() {
  },
  methods: {
    addCompareList(target) {
      this.shopCarBallEl = target;
      this.shopCarBall = true;
    },

    // 动画开始
    beforeEnter(el) {
      // 获取元素的大小及其相对于视口的位置
      if (process.client) {
          const dom = this.shopCarBallEl.getBoundingClientRect();
          const comparePosition = document
            .querySelector(".compare-list")
            .getBoundingClientRect();
          const offsetX = comparePosition.left - dom.left;
          const offsetY = dom.top - comparePosition.top-20;
          el.style.display = "";
          // y轴是曲直向上的，x轴是蜿蜒的向右的
          el.style.transform = `translate3D( -${offsetX}px,${offsetY}px,0)`;
      }
    },
    enter(el, done) {
      // 触发重绘，来实现动画的移动过程
      if (process.client) {
        this.ss = document.body.offsetHeight;
        el.style.transform = `translate3D( 0,0,0)`;
        el.addEventListener("transitionend", done);
      }
    },
    afterEnter(el) {
      this.shopCarBall = false;
      el.style.display = "none";
    },
  },
};
