
export default {
  props:{
    secondaryBanner:[]
  },
  data(){
    return {
      imageLoaded: false,
    }
  },
  computed:{
    com_secondaryBanner(){
      return this.secondaryBanner.slice(0,4)
    },
    com_secondaryBannerLth(){
      return this.com_secondaryBanner?.length
    },
  }
};
